/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useMemo, useState } from "react";
import cx from "classnames";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import postalCodes from "postal-codes-js";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import styles from "./StripePayments.module.scss";
import LoadingSmall from "../Loading/LoadingSmall";

import { sendToast } from "../../utils/helpers";

const CardTypes = {
  visa: "visa",
  amex: "amex",
  mastercard: "mastercard",
  discover: "discover",
  diners: "diners",
  jcb: "jcb",
  unionpay: "unionpay",
  unknown: "unknown",
};

const DefaultCard = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
  >
    <path
      fill="#2196F3"
      d="M53.333,85.333h405.333c29.455,0,53.333,23.878,53.333,53.333v234.667
      c0,29.455-23.878,53.333-53.333,53.333H53.333C23.878,426.667,0,402.789,0,373.333V138.667C0,109.211,23.878,85.333,53.333,85.333z"
    />
    <rect y="149.333" fill="#455A64" width="512" height="85.333" />
    <g>
      <path
        fill="#FAFAFA"
        d="M160,320H74.667C68.776,320,64,315.224,64,309.333s4.776-10.667,10.667-10.667H160
        c5.891,0,10.667,4.776,10.667,10.667S165.891,320,160,320z"
      />
      <path
        fill="#FAFAFA"
        d="M224,362.667H74.667C68.776,362.667,64,357.891,64,352c0-5.891,4.776-10.667,10.667-10.667H224
        c5.891,0,10.667,4.776,10.667,10.667C234.667,357.891,229.891,362.667,224,362.667z"
      />
    </g>
  </svg>
);

const UnionPay =
  "https://imgix.cosmicjs.com/7aaf9a10-c974-11eb-ba89-e7f98c8c358b-unionpay.png";
const Amex =
  "https://imgix.cosmicjs.com/7aadc550-c974-11eb-ba89-e7f98c8c358b-amex.png";
const Visa =
  "https://imgix.cosmicjs.com/7aaf4bf0-c974-11eb-ba89-e7f98c8c358b-visa.png";
const Discover =
  "https://imgix.cosmicjs.com/7aad5020-c974-11eb-ba89-e7f98c8c358b-discover.png";
const MasterCard =
  "https://imgix.cosmicjs.com/7aae3a80-c974-11eb-ba89-e7f98c8c358b-mastercard.png";
const Diners =
  "https://imgix.cosmicjs.com/7aae1370-c974-11eb-ba89-e7f98c8c358b-diners.png";

const MasterCardButton =
  "https://imgix.cosmicjs.com/17d5a260-c977-11eb-ba89-e7f98c8c358b-button-mastercard.png";
const VisaButton =
  "https://imgix.cosmicjs.com/17d4df10-c977-11eb-ba89-e7f98c8c358b-button-visa.png";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          padding: "0.75rem",
          fontSize: "1.25rem",
          color: "#606060",
          letterSpacing: "0.025em",
          fontWeight: "normal",
          fontFamily: "Inter, sans-serif",
          "::placeholder": {
            color: "#606060",
          },
          background: "#F0F2F1",
          lineHeight: "28px",
        },
        complete: {
          fontWeight: "normal",
          fontFamily: "Inter, sans-serif",
          color: "#606060",
          "::placeholder": {
            color: "#606060",
          },
        },
        valid: {
          fontWeight: "normal",
          fontFamily: "Inter, sans-serif",
          color: "#606060",
          "::placeholder": {
            color: "#606060",
          },
        },
        invalid: {
          fontWeight: "normal",
          fontFamily: "Inter, sans-serif",
          color: "#9e2146",
          "::placeholder": {
            color: "#606060",
          },
        },
        empty: {
          fontWeight: "normal",
          fontFamily: "Inter, sans-serif",
          background: "#606060",
          padding: "0.74rem !important",
          "::placeholder": {
            color: "#606060",
          },
        },
      },
    }),
    ["1rem"]
  );

  return options;
};

const StripePayments = ({
  noFloatingButton = false,
  secret = null,
  clientPay = false,
}) => {
  const stripe = useStripe();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const options = useOptions();
  const [cardType, setCardType] = useState(null) as any;
  const [successfulPayment, setSuccessfulPayment] = useState(false);
  // Address Details
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [shippingName, setShippingName] = useState("");

  const onSubmit = async (event) => {
    event.preventDefault();

    if (name === "") {
      sendToast("Please Enter Your name", { type: "error" });
    }
    if (!stripe || !elements || name === "") {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);
    if (!clientPay) {
      try {
        await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardNumberElement),
        });
        // const confirmPayment = await stripe.confirmCardPayment('', {
        //   payment_method: payload.paymentMethod
        // })
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    } else {
      if (!shippingName) {
        sendToast("Please enter your name under shipping details", {
          type: "error",
        });
        setLoading(false);
        return;
      }
      if (!country) {
        sendToast("Please select a country", { type: "error" });
        setLoading(false);
        return;
      }
      if (!line1) {
        sendToast("Please enter an address", { type: "error" });
        setLoading(false);
        return;
      }
      if (!city) {
        sendToast("Please enter a city", { type: "error" });
        setLoading(false);
        return;
      }
      if (!state) {
        sendToast("Please enter a state/province/region", { type: "error" });
        setLoading(false);
        return;
      }
      if (!postalCode) {
        sendToast("Please enter a postal/zip code", { type: "error" });
        setLoading(false);
        return;
      }

      const postalCodeValidation = postalCodes.validate(country, postalCode);

      if (typeof postalCodeValidation === "string") {
        sendToast(postalCodeValidation, { type: "error" });
        setLoading(false);
        return;
      }
      try {
        const result = await stripe.confirmCardPayment(secret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              name,
            },
          },
          shipping: {
            address: {
              line1, // (street, po box, or company name)
              line2, // apartment, suite, unit or building
              country, // two letter country code https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#CA
              city, // city, district, suburb, town or village
              postal_code: postalCode, // zip or postal code
              state, // state, county, province or region
            },
            name: shippingName, // Recipients name
          },
        });

        if (result.error) {
          setLoading(false);
          // Show error to your customer (e.g., insufficient funds)
          sendToast(result.error.message, { type: "error" });
          return;
        }
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          setLoading(false);
          setSuccessfulPayment(true);
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          // TODO: handle error
        }
      } catch (error) {
        if (error) {
          console.log(error);
        }
      }
    }
  };

  const getCardFromType = () => {
    switch (cardType) {
      case CardTypes.jcb:
      case CardTypes.unknown:
        return (
          <div className={styles.card}>
            <DefaultCard />
          </div>
        );
      case CardTypes.amex:
        return (
          <div className={styles.card}>
            <Imgix
              src={`${Amex}?auto=format`}
              height={33}
              width={55}
              htmlAttributes={{
                alt: "Amex",
                src: `${Amex}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        );
      case CardTypes.mastercard:
        return (
          <div className={styles.card}>
            <Imgix
              src={`${MasterCard}?auto=format`}
              height={33}
              width={55}
              htmlAttributes={{
                alt: "MasterCard",
                src: `${MasterCard}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        );
      case CardTypes.visa:
        return (
          <div className={styles.card}>
            <Imgix
              src={`${Visa}?auto=format`}
              height={33}
              width={55}
              htmlAttributes={{
                alt: "Visa",
                src: `${Visa}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        );
      case CardTypes.unionpay:
        return (
          <div className={styles.card}>
            <Imgix
              src={`${UnionPay}?auto=format`}
              height={33}
              width={55}
              htmlAttributes={{
                alt: "UnionPay",
                src: `${UnionPay}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        );
      case CardTypes.discover:
        return (
          <div className={styles.card}>
            <Imgix
              src={`${Discover}?auto=format`}
              height={33}
              width={55}
              htmlAttributes={{
                alt: "Discover",
                src: `${Discover}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        );
      case CardTypes.diners:
        return (
          <div className={styles.card}>
            <Imgix
              src={`${Diners}?auto=format`}
              height={33}
              width={55}
              htmlAttributes={{
                alt: "Diners",
                src: `${Diners}?blur=500&px=4&auto=format`,
              }}
              attributeConfig={{
                src: "data-src",
                srcSet: "data-srcset",
                sizes: "data-sizes",
              }}
              className="lazyload blur-up"
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  const handleCountrySelect = (value) => {
    setCountry(value);
  };

  const handleStateChange = (value) => {
    setState(value);
  };

  return (
    <div className={styles.paymentFieldsContainer}>
      {!successfulPayment && (
        <>
          <div className={styles.paymentTypes}>
            <div className={styles.type}>
              <Imgix
                src={`${VisaButton}?auto=format`}
                width={55}
                disableQualityByDPR
                htmlAttributes={{
                  alt: "Visa Button",
                  src: `${VisaButton}?blur=500&px=4&auto=format`,
                }}
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
                className="lazyload blur-up"
              />
            </div>
            <div className={styles.type}>
              <Imgix
                src={`${MasterCardButton}?auto=format`}
                height={33}
                width={55}
                htmlAttributes={{
                  alt: "Mastercard Button",
                  src: `${MasterCardButton}?blur=500&px=4&auto=format`,
                }}
                attributeConfig={{
                  src: "data-src",
                  srcSet: "data-srcset",
                  sizes: "data-sizes",
                }}
                className="lazyload blur-up"
              />
            </div>
          </div>
          <form onSubmit={(e) => onSubmit(e)}>
            <>
              <div className={styles.shippingDetails}>
                <h2>Shipping Details</h2>
                <div className={styles.fieldContainer}>
                  <label>Name</label>
                  <input
                    id="shippingName"
                    value={shippingName}
                    onChange={(e) => setShippingName(e.target.value)}
                    className={styles.defaultInput}
                    type="text"
                    placeholder="Name"
                    name="name"
                  />
                </div>
                <div
                  className={cx([styles.fieldContainer, styles.dropdownField])}
                >
                  <label>Country</label>
                  <CountryDropdown
                    classes={styles.countrySelector}
                    value={country}
                    valueType="short"
                    onChange={(val) => handleCountrySelect(val)}
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <label>Address Line 1</label>
                  <input
                    id="line1"
                    value={line1}
                    onChange={(e) => setLine1(e.target.value)}
                    className={styles.defaultInput}
                    type="text"
                    placeholder="Address Line 1"
                    name="Address Line 1"
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <label>Address Line 2</label>
                  <input
                    id="line2"
                    value={line2}
                    onChange={(e) => setLine2(e.target.value)}
                    className={styles.defaultInput}
                    type="text"
                    placeholder="Address Line 2"
                    name="Address Line 2"
                  />
                </div>
                <div className={styles.fieldContainer}>
                  <label>City</label>
                  <input
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className={styles.defaultInput}
                    type="text"
                    placeholder="City"
                    name="city"
                  />
                </div>
                {country !== "" && (
                  <div
                    className={cx([
                      styles.fieldContainer,
                      styles.dropdownField,
                    ])}
                  >
                    <label>State or Province</label>
                    <RegionDropdown
                      classes={styles.countrySelector}
                      countryValueType="short"
                      country={country}
                      value={state}
                      onChange={(val) => handleStateChange(val)}
                    />
                  </div>
                )}
                <div className={styles.fieldContainer}>
                  <label>Postal/Zip Code </label>
                  <input
                    id="postalCode"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    className={styles.defaultInput}
                    type="text"
                    placeholder="Postal/Zip Code"
                    name="Postal Code"
                  />
                </div>
              </div>
              <div className={styles.paymentDetails}>
                <h2>Payment Information</h2>

                <div className={styles.fieldContainer}>
                  <label>Full Name</label>
                  <input
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={styles.defaultInput}
                    type="text"
                    placeholder="Full Name"
                    name="name"
                  />
                </div>
                <div
                  className={cx([
                    styles.stripeFieldElement,
                    styles.cardContainer,
                  ])}
                >
                  <label>
                    Card number
                    <CardNumberElement
                      options={options}
                      onReady={() => {
                        console.log("CardNumberElement [ready]");
                      }}
                      onChange={(event) => {
                        setCardType(event.brand);
                        console.log("CardNumberElement [change]", event);
                      }}
                      onBlur={() => {
                        console.log("CardNumberElement [blur]");
                      }}
                      onFocus={() => {
                        console.log("CardNumberElement [focus]");
                      }}
                    />
                  </label>
                  {getCardFromType()}
                </div>
                <div className={styles.twoFields}>
                  <div className={styles.stripeFieldElement}>
                    <label>
                      Expiration date
                      <CardExpiryElement
                        options={options}
                        onReady={() => {
                          console.log("CardNumberElement [ready]");
                        }}
                        onChange={(event) => {
                          console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                          console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                          console.log("CardNumberElement [focus]");
                        }}
                      />
                    </label>
                  </div>
                  <div className={styles.stripeFieldElement}>
                    <label>
                      CVC
                      <CardCvcElement
                        options={options}
                        onReady={() => {
                          console.log("CardNumberElement [ready]");
                        }}
                        onChange={(event) => {
                          console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                          console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                          console.log("CardNumberElement [focus]");
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <p className={styles.title}>Security &amp; Privacy</p>
              <p>
                Bidddy takes your security and privacy with the highest
                standards. For more information, visit our{" "}
                <a href="/privacy-policy">Privacy Policy</a>
              </p>
              <div
                className={cx([
                  {
                    [styles.buttonContainer]: !noFloatingButton,
                    [styles.nonFloatingButtonContainer]: noFloatingButton,
                  },
                ])}
              >
                <button
                  className={styles.defaultButton}
                  type="submit"
                  disabled={!stripe}
                >
                  {loading && <LoadingSmall />}
                  {!loading && !noFloatingButton && "Add Payment Method"}
                  {!loading && noFloatingButton && "Pay"}
                </button>
              </div>
            </>
          </form>
        </>
      )}
      {successfulPayment && clientPay && (
        <div className={styles.successfulPayment}>
          <h3>Successfully Paid.</h3>
          <p>
            If you do not hear from the seller shortly. Please contact us at{" "}
            <a href="mailto:hello@bidddy.com">hello@bidddy.com.</a>
          </p>
        </div>
      )}
    </div>
  );
};

export default StripePayments;
