import { navigate } from "gatsby";
import React, { useEffect } from "react";
import Layout from "../components/Layout";
import PaymentPage from "../components/PageComponents/PaymentPage";
import { useAppDispatch } from "../store";
import { getPaymentDetailsFromRef } from "../store/payments/actions";

const PayPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const ref = urlSearchParams.get("ref");
      if (ref) {
        dispatch(getPaymentDetailsFromRef(ref));
      } else {
        navigate("/");
      }
    }
  }, []);

  return (
    <Layout
      title="Bidddy Pay | The World's First Automated Auctions for Social Media"
      canonical="https://bidddy.com/"
    >
      <PaymentPage />
    </Layout>
  );
};

export default PayPage;
