import React from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Imgix from "react-imgix";
import styles from "./PaymentPage.module.scss";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "lazysizes/plugins/blur-up/ls.blur-up";
import { selectPaymentDetails } from "../../../store/payments/selectors";
import Pay from "../../../types/Pay";
import StripePayments from "../../Stripe/StripePayments";

const stripePromise = loadStripe(process.env.GATSBY_PUBLIC_STRIPE_PUBLIC_KEY);

const PaymentPage = () => {
  const paymentDetails = useSelector((state) =>
    selectPaymentDetails(state)
  ) as Pay;
  return (
    <div className={styles.paymentPageContainer}>
      <h1>Bidddy Pay</h1>
      {/* Add Auction Details here */}
      {paymentDetails && paymentDetails.auction && (
        <div className={styles.auctionDetails}>
          <Imgix
            src={`${paymentDetails.auction.image_url}?auto=format`}
            height={540}
            width={540}
            htmlAttributes={{
              alt: paymentDetails.auction.name,
              src: `${paymentDetails.auction.image_url}?blur=500&px=4&auto=format`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload blur-up"
          />
          <h2>{paymentDetails.auction.name}</h2>
          <p className="my-4">{paymentDetails.auction.description_common}</p>
          <p>
            Your winning bid price: <br />
            <span>
              ${paymentDetails.auction.price.toFixed(2)}{" "}
              {paymentDetails.auction.currency}
            </span>
          </p>
        </div>
      )}
      <Elements stripe={stripePromise}>
        <StripePayments
          noFloatingButton
          secret={paymentDetails ? paymentDetails.client_secret : null}
          clientPay
        />
      </Elements>
    </div>
  );
};

export default PaymentPage;
